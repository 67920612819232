import { saveAs } from 'file-saver';
import { apiInstance } from '../api/requestInterceptor';

import { OemApproval } from '../models/oem-approval';
import { OemDetail, OemDetailRequest } from '../models/oem-detail';
import { ApprovalDocumentDetail } from '../models/approval-document-detail';
import { ShareByEmail } from '../models/share-by-email';
import { SearchOemResponse, OemOption } from '../models/oem';
import { BulkOEMApproval } from '../models/bulk-oem-approval';

/**
 * get oem product approvals
 * @param productId product id
 */
async function getOemProductApprovals(
  productId: number,
): Promise<OemApproval[]> {
  const res = await apiInstance.get<OemApproval[]>('/oemProductApprovals', {
    params: {
      productIds: productId,
    },
  });

  return res.data;
}

/**
 * get oem
 * @param oemId oem id
 */
async function getOem(oemId: string): Promise<OemDetail> {
  const res = await apiInstance.get<OemDetail>('/oems/' + oemId);

  return res.data;
}

/**
 * get oem detail
 * @param oemId oem id
 */
async function getOemDetail(oemId: string): Promise<OemDetail> {
  const res = await apiInstance.get<OemDetail>('/oems/' + oemId, {
    params: {
      includeApprovals: 'true',
    },
  });

  return res.data;
}

/**
 * download oem approval document
 * @param approvalDocId approval document id
 */
async function downloadOemApprovalDocument(
  approvalDocId: number | string,
): Promise<any> {
  const res = await apiInstance.get<any>(
    `/approvalDocuments/${approvalDocId}/download`,
    { responseType: 'blob' },
  );

  if (res.status === 200) {
    const filename =
      res.headers['content-disposition']?.split('filename=')[1] ||
      'approval-document.pdf';
    saveAs(
      res.data,
      decodeURIComponent(filename.replace('"', '').replace('"', '')),
    );
  }

  return res.data;
}

/**
 * get oem approval document
 * @param approvalDocId approval document id
 * @param oemId oem id
 */
async function getApprovalDocumentDetail(
  approvalDocId: string,
  oemId: string,
): Promise<ApprovalDocumentDetail> {
  const res = await apiInstance.get<ApprovalDocumentDetail>(
    `/approvalDocuments/${approvalDocId}?oemId=${oemId}`,
  );

  return res.data;
}

/**
 * Create new approval document
 * @param approvalDocId approval document id
 * @param issuedAt the issued time
 * @param documentId document id
 * @param oemId oem id
 */
async function uploadNewApprovalDoc(
  approvalDocId: string,
  issuedAt: string,
  documentId: number,
  oemId: string,
): Promise<ApprovalDocumentDetail> {
  const res = await apiInstance.post<ApprovalDocumentDetail>(
    `/approvalDocuments/${approvalDocId}/versions?oemId=${oemId}`,
    {
      issuedAt,
      documentId,
    },
  );

  return res.data;
}

/**
 * Create oem
 * @param oem oem data
 */
async function createOem(oem: OemDetailRequest): Promise<OemDetail> {
  const res = await apiInstance.post<any>('/oems', oem);

  return res.data;
}

/**
 * Update oem
 * @param oemId oem id
 * @param oem oem data
 */
async function updateOem(
  oemId: string,
  oem: OemDetailRequest,
): Promise<OemDetail> {
  const res = await apiInstance.put<any>(`/oems/${oemId}`, oem);

  return res.data;
}

/**
 * Activate oem
 * @param oemId oem id
 */
async function activateOem(oemId: number | string): Promise<void> {
  await apiInstance.put<any>(`/oems/${oemId}/activate`);
}

/**
 * Deactivate oem
 * @param oemId oem id
 */
async function deactivateOem(oemId: number | string): Promise<void> {
  await apiInstance.put<any>(`/oems/${oemId}/deactivate`);
}

async function activateDeactivateOemApproval(selectId: any): Promise<any> {
  const res = await apiInstance.put<any>(`/oemProductApprovals/deactivateids`, {
    ...selectId,
  });
  return res.data;
}

async function updateProductOemApproval(
  id: number,
  oemTypeId: number,
  bpConfidential: boolean,
  notes: string,
  restrictions: string | null,
  approvalDocumentId: number,
  selectedIds?: number[],
  documentIds?: number[],
) {
  if (restrictions === '') {
    restrictions = null;
  }
  await apiInstance.put<any>(`/oemProductApprovals/updateOemProductApproval`, {
    id: id,
    oemApprovalTypeId: oemTypeId,
    bpConfidential: bpConfidential,
    notes: notes,
    restrictions: restrictions,
    approvalDocumentId: approvalDocumentId,
    selectedId: selectedIds,
    documentIds: documentIds,
  });
}

/**
 * Activate approval
 * @param approvalId approval id
 */
async function activateApproval(approvalId: number | string): Promise<void> {
  await apiInstance.put<any>(`/oemProductApprovals/${approvalId}/activate`);
}

/**
 * Deactivate approval
 * @param approvalId approval id
 */
async function deactivateApproval(approvalId: number | string): Promise<void> {
  await apiInstance.put<any>(`/oemProductApprovals/${approvalId}/deactivate`);
}

/**
 * Deactivate approval
 * @param approvalId approval id
 */
async function shareByEmail(data: ShareByEmail): Promise<void> {
  await apiInstance.post<any>('/share', data);
}

/**
 * search oems
 * @param name oem name
 */
async function searchOems(name: string): Promise<SearchOemResponse> {
  const res = await apiInstance.get<SearchOemResponse>('/oems', {
    params: {
      name,
    },
  });

  return res.data;
}

/**
 * get oem product approvals
 * @param productIds product ids
 */
async function getOemProductApprovalsByIds(
  productIds: number[],
): Promise<OemApproval[]> {
  const productIdsStr = productIds.join(',');
  const productIdQuery =
    productIds && productIds.length > 0 ? 'productIds=' + productIdsStr : '';
  const res = await apiInstance.get<OemApproval[]>(
    '/oemProductApprovals?' + productIdQuery,
  );

  return res.data;
}

/**
 * Get all oems.
 */
async function getAllOems(): Promise<OemOption[]> {
  const res = await apiInstance.get<OemOption[]>('/oems/all');

  return res.data;
}

async function getOemsAct(): Promise<OemOption[]> {
  const res = await apiInstance.get<OemOption[]>('/oems/oemActivities');

  return res.data;
}

/**
 * Get active products.
 */
async function getActiveProducts(): Promise<OemOption[]> {
  const res = await apiInstance.get<OemOption[]>('/products/all', {
    params: {
      onlyActive: true,
    },
  });

  return res.data;
}

/**
 * Create bulk oem approval
 * @param data the bulk oem approval data
 */
async function createBulkOEMApproval(data: BulkOEMApproval): Promise<void> {
  await apiInstance.post<any>(
    '/approvalDocuments/bulkApprovalDocument/save',
    data,
  );
}

export {
  getOem,
  getOemProductApprovals,
  getOemDetail,
  downloadOemApprovalDocument,
  getApprovalDocumentDetail,
  createOem,
  updateOem,
  activateOem,
  deactivateOem,
  activateApproval,
  deactivateApproval,
  shareByEmail,
  searchOems,
  getOemProductApprovalsByIds,
  getAllOems,
  getOemsAct,
  getActiveProducts,
  createBulkOEMApproval,
  uploadNewApprovalDoc,
  activateDeactivateOemApproval,
  updateProductOemApproval,
};
